import React from "react";

import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/faq-page"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div>
                          <div id="search_form">
                            <input
                              type="text"
                              name="search_block_form"
                              id="searchword"
                              maxLength="128"
                            />
                            <input
                              type="submit"
                              name="suchen"
                              id="searchsubmit"
                              defaultValue
                            />
                            <input
                              type="hidden"
                              name="form_token"
                              defaultValue="fpRQ2dP7NgBmLR_3_abvt9HZCsr1ssctdnKOS8-vJyo"
                            />
                            <input
                              type="hidden"
                              name="form_id"
                              defaultValue="search_block_form"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="meta-text">
            IMODIUM®.&nbsp;Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
            </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/expertenrat/">Expertenrat</a>
                  <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p9">
                    Kontakt
                  </a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/wie-wirkt-imodium-akut/">Wie wirkt IMODIUM®?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Diagnose Reizdarm</a>
                    </li>
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-sidebar">
                <div id="block-block-2" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/teaser_03.jpg) no-repeat center top #eaf4f5",
                        padding: "50px 15px 20px 15px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4
                        style={{
                          "font-family": "Georgia",
                          "font-style": "italic",
                          "font-weight": "normal",
                          "font-size": "20px",
                          "line-height": "26px",
                          margin: "20px 0 10px 0"
                        }}
                      >
                        Sie erreichen uns kostenlos
                        <br />
                        00800 260 260 00
                      </h4>
                      <p>
                        <span
                          style={{
                            "font-size": "13px",
                            color: "#767575"
                          }}
                        >
                          mo-fr: 09:00 - 16:00 Uhr
                        </span>
                      </p>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                  <h1><div class="field field-name-field-title-text field-type-text field-label-hidden">
                  <div class="field-items"><div class="field-item even">FAQ</div></div></div></h1>
                    <div className="faq-content">
                      <div className="faq">
                        <div id="faq-expand-all">
                        </div>
                        <div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a
                                  href="/content/was-soll-ich-tun-wenn-ich-nicht-sicher-bin-was-meinen-wiederkehrenden-durchfall-auslöst/"
                                  id="n76"
                                >
                                  Was soll ich tun, wenn ich nicht sicher bin,
                                  was meinen wiederkehrenden Durchfall auslöst?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <p>
                                      Wenn Sie Durchfallsymptome haben, die
                                      länger anhalten, immer wiederkehren oder
                                      durch Blut oder andere Symptome begleitet
                                      werden, sollten Sie Ihren Arzt aufsuchen -
                                      besonders wenn Sie kurz davor in ein
                                      weniger entwickeltes Land gereist sind.
                                      Mögliche Ursachen für wiederkehrenden
                                      Durchfall können aber auch eine{" "}
                                      <a href="/nahrungsmittelallergie/">
                                        Nahrungsmittelunverträglichkeit{" "}
                                      </a>
                                      oder{" "}
                                      <a href="/stress-durchfall/">Stress </a>
                                      sein. &nbsp;In manchen dieser Fälle kann{" "}
                                      <a href="/imodium-produkte/">IMODIUM®</a>
                                      &nbsp;helfen, doch es ist wichtig, zuerst
                                      den Rat Ihres Arztes oder Apothekers
                                      einzuholen.
                                    </p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a
                                  href="/content/was-kann-ich-nehmen-wenn-ich-beim-besten-willen-keine-tabletten-schlucken-kann/"
                                  id="n75"
                                >
                                  Was kann ich nehmen, wenn ich beim besten
                                  Willen keine Tabletten schlucken kann?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <p>
                                      Keine Sorge, durch{" "}
                                      <a href="/imodium-akut-schmelztabletten/">
                                        IMODIUM® akut Schmelztabletten
                                      </a>{" "}
                                      erhalten Sie trotzdem Hilfe, wenn der
                                      akute Durchfall zuschlägt. Die Tabletten
                                      sind so beschaffen, dass sie sofort
                                      auf Ihrer Zunge schmelzen, ohne dass Sie
                                      dazu Wasser benötigen.
                                    </p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a
                                  href="/content/was-tue-ich-wenn-meine-kinder-durchfall-bekommen/"
                                  id="n74"
                                >
                                  Was tue ich, wenn meine Kinder Durchfall
                                  bekommen?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <div
                                      id="faqA18"
                                      style={{
                                        display: "block"
                                      }}
                                    >
                                      <p className="faqAnsFirst">
                                        Kinder verkraften den Verlust von
                                        Körperflüssigkeit durch Durchfall
                                        schlechter als Erwachsene; deshalb
                                        besteht der wichtigste Schritt darin,
                                        die Flüssigkeit schnell zu ersetzen. In
                                        der Apotheke erhalten Sie Trinklösungen
                                        zur Rehydrierung, die Ihnen dabei
                                        helfen. Wenn das Problem anhält oder das
                                        Kind unter 5 Jahre alt ist, ist es
                                        ratsam, einen Arzt aufzusuchen.
                                      </p>
                                      <p>
                                        Kinder ab 12 Jahre können bei akutem Durchfall mit{" "}
                                        <a href="/imodium-produkte/">
                                          IMODIUM®{" "}
                                        </a>
                                        behandelt werden.
                                        <br />
                                        In unserem Kapitel{" "}
                                        <a href="/durchfall-bei-kindern/">
                                          Durchfall bei Kindern
                                        </a>{" "}
                                        finden Sie weitere Informationen.
                                      </p>
                                    </div>
                                    <p>&nbsp;</p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a
                                  href="/content/es-ist-mir-peinlich-der-apotheke-nach-einem-durchfallmittel-zu-fragen-was-kann-ich-tun/"
                                  id="n73"
                                >
                                  Es ist mir peinlich, in der Apotheke nach
                                  einem Durchfallmittel zu fragen. Was kann ich
                                  tun?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <div
                                      id="faqA17"
                                      style={{
                                        display: "block"
                                      }}
                                    >
                                      <p className="faqAnsFirst">
                                        Durchfall kann auf mehr als eine Art
                                        unangenehm sein – besonders wenn in der
                                        Apotheke noch mehrere Leute hinter Ihnen
                                        warten, wenn Sie an der Reihe sind.
                                        Denken Sie einfach daran, dass Durchfall
                                        eine der häufigsten Beschwerden ist, mit
                                        denen Apotheker zu tun haben. Sie sind
                                        dafür ausgebildet, Ihr Problem zu
                                        verstehen, professionellen Rat zu
                                        erteilen und Sie zur richtigen
                                        Behandlung z.B. mit{" "}
                                        <a
                                          href="/imodium-produkte/"
                                          style={{
                                            "font-size": "13.008px"
                                          }}
                                        >
                                          IMODIUM®
                                        </a>{" "}
                                        zu beraten.&nbsp;Wenn es Ihnen besonders
                                        peinlich ist, können Sie vielleicht auch
                                        darum bitten, den Apotheker unter vier
                                        Augen zu sprechen.
                                      </p>
                                      <p>&nbsp;</p>
                                    </div>
                                    <p>&nbsp;</p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a
                                  href="/content/warum-bekomme-ich-häufig-durchfall-während-meiner-periode/"
                                  id="n72"
                                >
                                  Warum bekomme ich häufig Durchfall während
                                  meiner Periode?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <div
                                      id="faqA16"
                                      style={{
                                        display: "block"
                                      }}
                                    >
                                      <p className="faqAnsFirst">
                                        Viele Frauen leiden während der Menstruation unter Durchfall. Man geht davon aus, dass Veränderungen im Hormonhaushalt dafür verantwortlich sind. Auch bei akutem Durchfall während der Menstruation kann eine symptomatische Behandlung mit{" "}
                                        <a href="/imodium-produkte/">
                                          IMODIUM®{" "}
                                        </a>
                                        in Frage kommen.
                                      </p>
                                      <p>
                                        In unserem Kapitel{" "}
                                        <a
                                          href="/durchfall-wahrend-ihrer-periode/"
                                          title="Durchfall während der Periode"
                                        >
                                          Durchfall während der Periode
                                        </a>{" "}
                                        finden Sie weitere Informationen.
                                      </p>
                                    </div>
                                    <p>&nbsp;</p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a
                                  href="/content/mein-magen-reagiert-manchmal-empfindlich-auf-stark-gewürzte-speisen-was-kann-ich-dagegen-tun/"
                                  id="n71"
                                >
                                  Mein Magen reagiert manchmal empfindlich auf
                                  stark gewürzte Speisen. Was kann ich dagegen
                                  tun?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <p>
                                      Stark gewürzte Speisen sind ein häufiger
                                      Grund für Durchfall – manche
                                      Verdauungssysteme sind einfach nicht für
                                      die Schärfe geschaffen. Wenn Sie pikantes
                                      Essen mögen und nicht darauf verzichten
                                      wollen, sollten Sie ein Durchfallmittel
                                      zur Hand haben!{" "}
                                      <a href="/imodium-produkte/">IMODIUM® </a>
                                      ist zur symptomatischen Behandlung von
                                      akuten Durchfällen zugelassen.
                                    </p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a
                                  href="/content/wenn-ich-einen-vortrag-halten-muss-werde-ich-so-nervös-dass-sich-mir-der-magen-umdreht-warum/"
                                  id="n69"
                                >
                                  Wenn ich einen Vortrag halten muss, werde ich
                                  so nervös, dass sich mir der Magen umdreht.
                                  Warum ist das so?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <div
                                      id="faqA13"
                                      style={{
                                        display: "block"
                                      }}
                                    >
                                      <p className="faqAnsFirst">
                                        Durch die Angst vor öffentlichen
                                        Auftritten ausgelöster Durchfall ist ein
                                        so weit verbreitetes Problem, dass er
                                        sogar einen eigenen Namen hat – PAD
                                        (Performance Anxiety Diarrhoea) oder
                                        Durchfall durch Versagensangst.
                                      </p>
                                      <p>
                                        In unserem Kapitel{" "}
                                        <a
                                          href="/stress-durchfall/"
                                          title="Stress und Durchfall"
                                        >
                                          Stress und Durchfall
                                        </a>{" "}
                                        finden Sie weitere Informationen.
                                      </p>
                                    </div>
                                    <p>&nbsp;</p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a
                                  href="/content/warum-bekomme-ich-immer-durchfall-wenn-ich-große-mengen-esse-und-trinke/"
                                  id="n68"
                                >
                                  Warum bekomme ich immer Durchfall, wenn ich
                                  große Mengen esse und trinke?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <p>
                                      Übermäßiges Essen und Trinken regt unser
                                      Verdauungssystem dazu an, zu schnell zu
                                      arbeiten und dadurch kann es zu Durchfall kommen. Versuchen Sie deshalb, immer
                                      vernünftig zu essen und Exzesse zu
                                      vermeiden! Wenn jedoch akuter Durchfall
                                      auftritt, kann&nbsp;
                                      <a href="/imodium-produkte/">IMODIUM®</a>
                                      &nbsp;helfen.
                                    </p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a
                                  href="/content/warum-leide-ich-im-urlaub-unter-durchfall/"
                                  id="n67"
                                >
                                  Warum leide ich im Urlaub unter Durchfall?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <div
                                      id="faqA11"
                                      style={{
                                        display: "block"
                                      }}
                                    >
                                      <p className="faqAnsFirst">
                                        Schlechte Hygiene, Verunreinigungen im
                                        Essen oder im Wasser und die
                                        Klimaveränderung sorgen zusammen dafür,
                                        dass Reisedurchfall ein sehr häufiges
                                        Leiden ist. Er kann auch allein schon
                                        durch eine veränderte Ernährung
                                        ausgelöst werden. Durchfall kann einen
                                        wohl verdienten Urlaub völlig verderben,
                                        deshalb sollten Sie auf jeden Fall ein
                                        Durchfallmittel mitnehmen.
                                      </p>
                                      <p>
                                        <a href="/imodium-akut-schmelztabletten/">
                                          IMODIUM<sup>®</sup> akut
                                          Schmelztabletten{" "}
                                        </a>
                                        bieten aufgrund ihrer speziellen Darreichungsform schnelle und praktische Hilfe. Die Tabletten schmelzen auf der Zunge, ohne dass Sie dazu Wasser benötigen, und haben einen angenehmen frischen Minzgeschmack.
                                      </p>
                                      <p>
                                        In unserem Kapitel{" "}
                                        <a
                                          href="/reisedurchfall-vorbeugen/"
                                          title="Durchfall auf Reisen"
                                        >
                                          Durchfall auf Reisen
                                        </a>{" "}
                                        finden Sie weitere Informationen.
                                      </p>
                                    </div>
                                    <p>&nbsp;</p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a
                                  href="/content/kann-ich-imodium-bei-reizdarm-anwenden/"
                                  id="n66"
                                >
                                  Kann ich IMODIUM® bei Reizdarm anwenden?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <div
                                      id="faqA10"
                                      style={{
                                        display: "block"
                                      }}
                                    >
                                      <p className="faqAnsFirst">
                                        <a href="/imodium-produkte/">
                                          IMODIUM®{" "}
                                        </a>
                                        ist in Apotheken erhältlich und kann zur
                                        Behandlung kurz andauernder, durch einen
                                        Reizdarm bedingter akuten
                                        Durchfallattacken verwendet werden. Es
                                        ist jedoch wichtig, dass Sie zuerst von
                                        Ihrem Arzt eine Diagnose erstellen
                                        lassen.
                                      </p>
                                      <p>
                                        In unserem Kapitel{" "}
                                        <a
                                          href="/behandlung-eines-reizdarms/"
                                          title="Behandlung eines Reizdarms"
                                        >
                                          Behandlung eines Reizdarms
                                        </a>{" "}
                                        finden Sie weitere Informationen.
                                      </p>
                                    </div>
                                    <p>&nbsp;</p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a
                                  href="/content/wann-stellt-sich-nach-dem-durchfall-der-normale-stuhlgang-wieder-ein/"
                                  id="n65"
                                >
                                  Wann stellt sich nach dem Durchfall der
                                  normale Stuhlgang wieder ein?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <p>
                                      Bei Durchfall wird der Darm häufig
                                      komplett entleert. Danach kann es ein bis
                                      zwei Tage dauern, bis er sich wieder
                                      gefüllt hat und sich der normale Rhythmus
                                      wieder einspielt.
                                    </p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a
                                  href="/content/kann-ich-imodium-verwenden-wenn-ich-schwanger-bin-oder-stille/"
                                  id="n64"
                                >
                                  Kann ich IMODIUM® verwenden, wenn ich
                                  schwanger bin oder stille?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-field-detailed-question field-type-text-long field-label-hidden">
                                <div className="field-items">
                                  <div className="field-item even" />
                                </div>
                              </div>
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <p>
                                      Die Verwendung von{" "}
                                      <a href="/imodium-produkte/">IMODIUM®</a>{" "}
                                      während der Schwangerschaft oder des
                                      Stillens wird nicht empfohlen. Lassen Sie
                                      sich von Ihrem Arzt oder Ihrer Hebamme zu
                                      alternativen Behandlungen beraten.
                                    </p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a
                                  href="/content/wirken-orale-rehydrierungslösungen-gegen-durchfall/"
                                  id="n63"
                                >
                                  Wirken orale Rehydrierungslösungen gegen
                                  Durchfall?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-field-detailed-question field-type-text-long field-label-hidden">
                                <div className="field-items">
                                  <div className="field-item even" />
                                </div>
                              </div>
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <div
                                      id="faqA7"
                                      style={{
                                        display: "block"
                                      }}
                                    >
                                      <p className="faqAnsFirst">
                                        Orale Rehydrierungslösungen dienen
                                        lediglich dazu, verlorene Flüssigkeiten
                                        und Elektrolyte (Salze) im Körper zu
                                        ersetzen, um eine Austrocknung zu
                                        verhindern. Sie haben keinen Einfluss
                                        auf die Durchfallsymptome.
                                      </p>
                                      <p>
                                        Weitere Informationen finden Sie im
                                        Kapitel{" "}
                                        <a
                                          href="/ursachen-von-durchfall/"
                                          title="Ursachen von Durchfall"
                                        >
                                          Ursachen von Durchfall
                                        </a>
                                        .
                                      </p>
                                    </div>
                                    <p>&nbsp;</p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a
                                  href="/content/können-kinder-imodium-verwenden/"
                                  id="n62"
                                >
                                  Können Kinder IMODIUM® verwenden?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <p>
                                      Nur Kinder und Jugendliche ab 12 Jahren
                                      können{" "}
                                      <a href="/imodium-produkte/">IMODIUM®</a>
                                      &nbsp;bei akuten Durchfällen anwenden. Bei Kindern unter 12
                                      Jahren können orale Rehydrierungslösungen
                                      eingesetzt werden, um die Dehydrierung bei
                                      Durchfall zu vermeiden. In unserem
                                      Kapitel&nbsp;{" "}
                                      <a href="/durchfall-bei-kindern/">
                                        Durchfall bei Kindern
                                      </a>{" "}
                                      finden Sie weitere Informationen.
                                    </p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                              <a href="/content/brauche-ich-für-imodium-ein-rezept/" id="n60">
                              Brauche ich für IMODIUM® ein Rezept?</a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <p>
                                      Nein. Alle Produkte von{" "}
                                      <a href="/imodium-produkte/">IMODIUM®</a>
                                      &nbsp;sind rezeptfrei in Apotheken
                                      erhältlich.
                                    </p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a href="/content/was-ist-durchfall/" id="n54">
                                  Was ist Durchfall?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-field-detailed-question field-type-text-long field-label-hidden">
                                <div className="field-items">
                                  <div className="field-item even" />
                                </div>
                              </div>
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <div
                                      id="faqA1"
                                      style={{
                                        display: "block"
                                      }}
                                    >
                                      <p className="faqAnsFirst">
                                        Wenn Ihr Verdauungssystem normal
                                        arbeitet, gelangen Nahrung und
                                        Flüssigkeit vom Magen in den Dünndarm
                                        und von dort in den Dickdarm. Der
                                        Dünndarm und der Dickdarm sind mit
                                        Zellen ausgekleidet, die das Wasser und
                                        die Nährstoffe, die der Körper benötigt,
                                        aus dem Nahrungsbrei aufnehmen. Die unverdaulichen Teile der Nahrung und
                                        das übrige Wasser werden in Form von
                                        Stuhl durch den Körper befördert.
                                      </p>
                                      <p>
                                        Verschiedene Ursachen können dazu führen, dass sich die normalerweise langsame, wellenförmige Bewegung des Darm übermäßig verstärkt. Dadurch wird der Nahrungsbrei zu schnell durch den Darm befördert und der Körper kann weniger Nährstoffe und Flüssigkeit daraus aufnehmen. Zusätzlich kann die Sekretion (Abgabe) von Flüssigkeit und Nährstoffen in den Darm erhöht sein. Das Ergebnis ist wässriger Stuhl - besser bekannt als Durchfall.
                                      </p>
                                      <p>
                                        Besuchen Sie unsere Seite zum Thema{" "}
                                        <a
                                          href="/ursachen-von-durchfall/"
                                          title="Ursachen von Durchfall"
                                        >
                                          Ursachen von Durchfall
                                        </a>
                                        , um mehr zu erfahren.
                                      </p>
                                    </div>
                                    <p>&nbsp;</p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a
                                  href="/content/was-verursacht-akuten-kurz-andauernden-durchfall/"
                                  id="n46"
                                >
                                  Was verursacht akuten (kurz andauernden)
                                  Durchfall?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="faq-detailed-question">                                
                              </div>
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <div
                                      id="faqA2"
                                      style={{
                                        display: "block"
                                      }}
                                    >
                                      <p className="tipsPara faqAnsFirst">
                                        Akuter Durchfall kann viele verschiedene
                                        Ursachen haben. Hier einige der
                                        wichtigsten:
                                      </p>
                                      <ul>
                                        <li>
                                          Virale oder bakterielle Infektion
                                        </li>
                                        <li>
                                          Allergien oder bestimmte
                                          Nahrungsmittelunverträglichkeiten
                                        </li>
                                        <li>
                                          Verzehr von zu viel stark gewürztem
                                          oder fettigem Essen
                                        </li>
                                        <li>Übermäßiger Alkoholkonsum</li>
                                        <li>Stress</li>
                                        <li>
                                          Reizdarm (Irritable Bowel Syndrome -
                                          IBS)
                                        </li>
                                        <li>Menstruation</li>
                                      </ul>
                                      <p>
                                        Weitere Informationen über akuten
                                        Durchfall und seine Behandlung finden
                                        Sie im Kapitel{" "}
                                        <a
                                          href="/fakten-uber-durchfall/"
                                          title="Fakten über Durchfall"
                                        >
                                          Fakten über Durchfall
                                        </a>
                                        .
                                      </p>
                                    </div>
                                    <p>&nbsp;</p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="faq-question-answer">
                            <div className="faq-question faq-dt-hide-answer">
                              <span datatype property="dc:title">
                                <a href="/content/durchfall-was-tun/" id="n45">
                                  Durchfall - was tun?
                                </a>
                              </span>{" "}
                            </div>
                            <div className="faq-answer faq-dd-hide-answer">
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div
                                    className="field-item even"
                                    property="content:encoded"
                                  >
                                    <div className="faqRow lastFaq">
                                      <div>
                                        <span
                                          style={{
                                            "font-size": "13.008px",
                                            "line-height": "1.538em"
                                          }}
                                        >
                                          Bei Fieber, Blut oder Schleim im Stuhl oder wenn die Durchfälle sich nach 48 Stunden nicht bessern, sollten Sie einen Arzt aufsuchen. Auch bei Kindern oder älteren Personen ist Vorsicht geboten und Rücksprache mit dem Arzt ratsam.
Kurz andauernde Durchfall-Attacken können Sie in der Regel selbst behandeln. Wenn andere Maßnahmen (z.B. Diät oder Substitution von Wasser und Elektrolyten) nicht helfen, kann akuter Durchfall mit
                                        </span>
                                        <span
                                          style={{
                                            "font-size": "13.008px",
                                            "line-height": "1.538em"
                                          }}
                                        >
                                          &nbsp;
                                        </span>
                                        <a
                                          href="/imodium-akut-schmelztabletten/"
                                          style={{
                                            "font-size": "13.008px",
                                            "line-height": "1.538em"
                                          }}
                                        >
                                          IMODIUM®
                                        </a>
                                        <span
                                          style={{
                                            "font-size": "13.008px",
                                            "line-height": "1.538em"
                                          }}
                                        >
                                          &nbsp;gelindert werden. In Apotheken sind verschiedene rezeptfreie&nbsp;
                                        </span>
                                        <a
                                          href="/imodium-produkte/"
                                          style={{
                                            "font-size": "13.008px",
                                            "line-height": "1.538em"
                                          }}
                                        >
                                          IMODIUM®{" "}
                                        </a>
                                        <span
                                          style={{
                                            "font-size": "13.008px",
                                            "line-height": "1.538em"
                                          }}
                                        >
                                          Produkte erhältlich:
                                        </span>
                                      </div>
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        className="dashed"
                                        style={{
                                          width: "100%"
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                width: "50%"
                                              }}
                                            >
                                              <div className="faqHead1">
                                                Produkt
                                              </div>
                                            </td>
                                            <td
                                              style={{
                                                width: "50%"
                                              }}
                                            >
                                              <div className="faqHead2">
                                                Erhältliche Packungsgrößen
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <div className="faqData1">
                                                IMODIUM® akut 2 mg
                                                Schmelztabletten
                                              </div>
                                            </td>
                                            <td>
                                              <div className="faqData2">
                                                10, 20
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>IMODIUM® 2 mg Kapseln</td>
                                            <td>
                                              <div className="faqData2">
                                                10, 20
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <p>&nbsp;</p>
                                      <p>
                                        Weitere Informationen finden Sie in der
                                        IMODIUM®{" "}
                                        <a href="/imodium-produkte/">
                                          Produktübersicht
                                        </a>
                                        .
                                      </p>
                                    </div>
                                    <p>&nbsp;</p>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div id="footer">
          <div className="wrap">
            <div className="sitemap">
              <div className="col1">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/wie-wirkt-imodium-akut/" title>
                      Wie wirkt IMODIUM®?
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/loperamid/" title>
                      Der Wirkstoff: Loperamid
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last expanded">
                    <a href="/imodium-produkte/" title>
                      Produktübersicht
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/imodium-akut-schmelztabletten/" title>
                          IMODIUM® akut Schmelztabletten
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/imodium-kapseln/" title>
                          IMODIUM® Kapseln
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col2">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/fakten-uber-durchfall/" title>
                      Fakten über Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/tipps-bei-durchfall/" title>
                      Tipps bei Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first last leaf">
                        <a href="/tipps-fuer-den-winter/" title>
                          Tipps für den Winter
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/ursachen-von-durchfall/" title>
                      Ursachen von Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/richtig-essen/" title>
                      Gesunde Essgewohnheiten
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/nahrungsmittelallergie/" title>
                          Nahrungsmittelallergien und -unverträglichkeiten
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/lebensmittelvergiftung/" title>
                          Lebensmittelvergiftung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/rezepte/" title>
                          Gesunde Rezepte
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="expanded">
                    <a href="/stress-durchfall/" title>
                      Stress und Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/hohe-arbeitsbelastung/" title>
                          Hohe Arbeitsbelastung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/prufungssituationen/" title>
                          Prüfungssituationen
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/chronischer-durchfall/" title>
                      Chronischer Durchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/gastroenteritis/" title>
                      Magen-Darm-Grippe
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/norovirus/" title>
                      Norovirus
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/durchfall-wahrend-ihrer-periode/" title>
                      Durchfall während Ihrer Periode
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col3">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/diagnose-reizdarm/" title>
                      Diagnose Reizdarm (IBS)
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/behandlung-eines-reizdarms/" title>
                      Behandlung eines Reizdarms
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reizdarm-ernahrung/" title>
                      Ernährung bei Reizdarm
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/reisedurchfall-vorbeugen/" title>
                      Vorbeugung von Reisedurchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reiseapotheke/" title>
                      Reiseapotheke
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reisedurchfall-risikofaktoren/" title>
                      Reisedurchfall Risikofaktoren
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reisedurchfall-infos-und-fakten/" title>
                      Infos &amp; Fakten zu Reisedurchfall
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col4">
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/durchfall-bei-kindern/" title>
                      Durchfall bei Kindern
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/infomaterial/" title>
                      Infomaterial
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf active-trail">
                    <a href="/faq-page/" title className="active-trail active">
                      FAQ
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/glossar/" title>
                      Glossar
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="clear" />
            </div>
            <div className="subnavi">
              <div className="region region-metanavi">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/impressum/" title>
                      Impressum
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/kontakt/" title>
                      Kontakt
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/rechtliche-hinweise/" title>
                      Nutzungsbedingungen
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/datenschutz/" title>
                      Datenschutz
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/cookie-richtlinien/" title>
                      Cookie-Richtlinien
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                     IMODIUM®. Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                  </p>
                  <p>
                    <br />© Johnson &amp; Johnson GmbH 2011-2019. Diese Website
                    wird von der Johnson &amp; Johnson GmbH veröffentlicht, die
                    allein für deren Inhalte verantwortlich ist. Sie ist für
                    Besucher aus Österreich bestimmt.
                    <br />
                    Letzte Aktualisierung am: 23. Dezember 2021, AT-IM-2100045
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n<!--//--><!--[CDATA[//--><!--\nfunction euCookieComplianceLoadScripts() {}\n//--><!--]]-->\n"
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--//--><!--[CDATA[//--><!--\nvar eu_cookie_compliance_cookie_name = "";\n//--><!--]]-->\n'
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/modules/contrib/eu_cookie_compliance/js/eu_cookie_compliance.js?qbta11"
        />
      </div>
    );
  }
}

export default Page;
